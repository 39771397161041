import React from 'react';
import { SOCIAL } from './data/social';

const social = SOCIAL.map((item, idx) => (
  <div className={`socialButton ${item.classTile}`} key={`curr-${idx}`}>
    <a href={item.link} target="_blank" rel="noopener noreferrer" title={item.title}>{item.component}</a>
  </div>
));

const SocialButton = () => (
  <div className="socialContainer">
    {social}
  </div>
);

export default SocialButton;
