import styled from 'styled-components';
import { colors } from '../../tokens';

export const FooterWrapper = styled.footer`
  text-align: left;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: ${colors.primary};
  color: ${colors.textLightest};
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  & nav {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    max-width: 840px;
    margin: 0 auto;

    .footer-col {
      flex: 1 auto;
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 1em;
      padding-right: 1em;
    }
  }

  & a {
    color: ${colors.textLightest};
    font-weight: bold;

    &:hover {
      color: ${colors.textLightestHover};
      /* border-bottom: 1px dotted ${colors.textLightestHover}; */
    }
  }

  .footer-col {
    .footer-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      
      @media (max-width: 564px) {
        flex-wrap: wrap;
      }
    }
    > p {
      margin: 0;
    }
  }
  
  .footer-title {
    margin: 0 0 1rem;
  }

  .footer-item {
    padding: 0.25rem 0;
    color: ${colors.textLightest};
  }

  .footer-heart {
    color: ${colors.heartFooter};
  }

  .footer-item-text {
    padding: 0.1rem 0;
    color: ${colors.textLightest};
    font-size: .8rem;
  }

  .footer-header {
    order: 1;
    margin: 0 0.25rem;
    margin-right: 0.25rem;
    padding: 0.25rem;
  }

  @media (max-width: 564px) {
    .footer-col:first-child {
      width: 100%;
    }
  }
  
  .socialContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    text-align: center;
    margin: .5rem 0;
    min-height: 1rem;
    background-color: transparent;
    .socialButton {
      flex: 0 1 auto;
      /*border: 1px grey solid;
      border-radius: .3rem;*/
      text-align:center;

      margin: .5rem;
      padding: .5rem;

      @media (max-width: 564px) {
        margin: .07rem;
      }

      &:hover {
        background-color:  ${colors.hoverLinkFooter};
      }
      a {
        &:hover {
          color: white;
        }
      }
    }
  }
`;
